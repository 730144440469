import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import React from "react";

import "./src/css/index.css";
import getUniqueId from "./src/utils/getUniqueId";

declare function cwr(operation: string, payload: any): void;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours (required for localStorage caching)
      staleTime: 1000 * 60 * 1, // 1 min
    },
  },
});

persistQueryClient({
  queryClient,
  persister: createSyncStoragePersister({ storage: window.localStorage }),
});

export const wrapPageElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};

export function onRouteUpdate({ location }) {
  if (process.env.NODE_ENV !== "production" || typeof cwr !== "function") {
    return null;
  }

  var sendPageView = function sendPageView() {
    cwr("recordPageView", location.href);
  };

  if ("requestAnimationFrame" in window) {
    requestAnimationFrame(function () {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
}

function logExceptionsToGA() {
  if (typeof window.onerror !== "object") return;
  // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
  window.onerror = function (msg, url, error) {
    const description = [
      "Message: " + msg,
      "URL: " + url,
      "Error object: " + JSON.stringify(error),
    ].join(" - ");

    if (window.gtag) {
      window.gtag("event", "exception", {
        description,
      });
    }
  };
}

export function onClientEntry() {
  logExceptionsToGA();
  getUniqueId();
}
